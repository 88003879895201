import {
  Affiliation,
  Analyzation,
  AnalyzationStatus,
  Clinics,
  Industry,
  Occupation,
  Patients,
} from "@sleepwell_new_platform/common-types";
import { anaylzationColumns } from "../../static/analyzation";
import { getAnalyzationStatusLabel } from "../analyzations";
import dayjs from "dayjs";
import { AnalyzationResult } from "@sleepwell_new_platform/common-types/src/analyzationResults";
import { DiseasWithId } from "@sleepwell_new_platform/common-types/src/diseas";
import { roundToFourthDecimalPlace, roundToSecondDecimalPlace } from "./roundFunctions";

export const convertToCSVFormat = (
  selectedAnalyzation: Analyzation[],
  patients: Patients[],
  affiliations: Affiliation[],
  analyzationResults: AnalyzationResult[],
  diseases: DiseasWithId[],
  clinic: Clinics | null,
  industries?: Industry[],
  occupations?: Occupation[]
) => {
  const list: string[][] = [];
  const headerList: string[] = [];
  const moduleName = selectedAnalyzation[0]?.moduleName;
  const isSEASG = moduleName === "SEAS-G" ? true : false;
  anaylzationColumns.forEach((column) => {
    if(isSEASG && column.type === "analyzationResult-calculatedRejectItemList") return;
    headerList.push(column.label ?? "");
  });
  list.push(headerList);
  selectedAnalyzation.forEach((analyzation) => {
    const row: string[] = [];
    anaylzationColumns.forEach((column) => {
      const value = analyzation[column.value as keyof Analyzation];
      const isAllowedBrowseAllReport =
        clinic?.isAllowedBrowseAllReport ?? false;
      const reportType = clinic?.reportType;
      if (column.value === "status") {
        row.push(
          getAnalyzationStatusLabel(String(value ?? "") as AnalyzationStatus)
        );
      } else if (
        ["measureStartDate", "firstAnalyzeRequestDate"].includes(column.value)
      ) {
        row.push(
          value !== 0
            ? dayjs(value as number).format("YYYY-MM-DD HH:mm:ss")
            : "-"
        );
      } else if (column.value === "affiliation") {
        const affiliationId = analyzation.affiliationId;
        const affiliation = affiliations.find(
          (affiliation) => affiliation.id === affiliationId
        );
        row.push(affiliation?.name ?? "");
      } else if (column.value === "patientNumber") {
        const patientId = analyzation.patientId;
        const patient = patients.find((patient) => patient.id === patientId);
        row.push(patient?.patientNumber ?? "");
      } else if (column.value === "patientName") {
        const patientId = analyzation.patientId;
        const patient = patients.find((patient) => patient.id === patientId);
        row.push(patient?.name ?? "");
      } else if (column.value === "version") {
        row.push(analyzation?.analyzerVersionName ?? "");
      } else if (column.value === "rejectMessageList") {
        if (!analyzation?.rejectMessageList) {
          row.push("");
          return;
        }
        const message = Array.from(
          new Set(
            analyzation?.rejectMessageList.map(
              (rejectMessage) => rejectMessage.systemMessage
            )
          )
        ).join("/");
        row.push(message ?? "");
      } else if (column.type === "analyzationResult") {
        const status = analyzation.status;
     
        // -----------------------
        // 結果から出てくる値
        // -----------------------
        
        if(['waitingForConfirm', 'requestForReMeasure', 'failed', 'waitingForConfirmReport'].includes(status)) {
          //「確認待ち」「確認待ち(レポート作成済み）」「再計測依頼」「処理不可能」の場合は「-」を表示
          row.push("-");
          return;
        }
        const analyzationResult = analyzationResults.find(
          (analyzationResult) =>
            analyzationResult?.analyzationId === analyzation.id
        );
        const isAllWake = analyzationResult?.sl === 0 ? true : false;
        if (analyzationResult) {
          if (column.value === "totalJudge") {
            //総合判定
            if (!isAllowedBrowseAllReport && reportType === "research") {
              row.push("-");
            } else {
              row.push(
                String(
                  analyzationResult
                    ? analyzationResult?.totalJudgeInfo.totalJudge
                    : "-"
                )
              );
            }
          } else if (column.value === "firstSleepCycleDeltaPow") {
            row.push(
              String(
                analyzationResult
                  ? roundToSecondDecimalPlace(analyzationResult?.firstNonremDeltaPowerInfo?.totalDeltaPower)
                    ?? "-"
                  : "-"
              )
            );
          } else if (column.value === "firstSleepCycleTime") {
            row.push(
              String(
                analyzationResult
                  ? roundToSecondDecimalPlace(analyzationResult?.firstNonremDeltaPowerInfo?.totalSleepCycleTime) ?? "-"
                  : "-"
              )
            );
          } else if (column.value === "firstSleepCycleDeltaPowParMin") {
            row.push(
              String(
                analyzationResult
                  ? roundToSecondDecimalPlace(analyzationResult?.firstNonremDeltaPowerInfo
                      .totalDeltaPowParMin) ?? "-"
                  : "-"
              )
            );
          } else if (
            column.value === "firstSleepCycleDeltaPowParToalDeltaPow"
          ) {
            row.push(
              String(
                analyzationResult
                  ? roundToSecondDecimalPlace(analyzationResult?.totalNonremDeltaPowParMin) ?? "-"
                  : "-"
              )
            );
          } else if (column.value === "totalNonremDeltaPowParMin") {
            row.push(
              String(
                analyzationResult
                  ? roundToSecondDecimalPlace(analyzationResult?.firstNonremDeltaPowerInfo
                      .deltaPowParTotalDeltaPow) ?? "-"
                  : "-"
              )
            );
          } else if (column.value === "matureSleepBrainwaveAge") {
            //脳波データを整数にする
            // 脳波データ
            const brainwaveAge = analyzationResult?.matureSleepBrainwaveAge;
            if (!isAllowedBrowseAllReport && reportType === "research") {
              row.push("-");
            } else {
              if(analyzation.age >= 19){
                row.push(String(brainwaveAge ? Math.round(brainwaveAge) : ""));
              } else {
                row.push("-");
              }
            }
          } else if (column.value === "beforeW2hWaso") {
            row.push(
              String(
                analyzationResult
                  ? roundToSecondDecimalPlace(((analyzationResult?.beforeW2hWaso ?? 0) /120) * 100) ?? "-"
                  : "-"
              )
            );
          } else if (column.value === "spt") {
            row.push(
              String(
                isAllWake ? '-': analyzationResult?.spt)
            );
          } else if (column.value === "tst") {
            row.push(
              String(
                isAllWake ? '-': analyzationResult?.tst)
            );
          } else if (["firstSleepCycle","firstSleepDeviation","secondSleepCycle", "secondSleepDeviation", "thirdSleepCycle", "thirdSleepDeviation", "fourthSleepCycle", "fourthSleepDeviation", "fifthSleepCycle", "fifthSleepDeviation", "sixthSleepCycle", "sixthSleepDeviation", "beforeSptWakeResponseParEpoch"].includes(column.value)) {
            // 0の場合−で追加する
            row.push(
              String(
                (analyzationResult[column.value as keyof AnalyzationResult] === 0 ? "-" : analyzationResult[column.value as keyof AnalyzationResult] ? roundToSecondDecimalPlace(analyzationResult[column.value as keyof AnalyzationResult] as number) : "-")
              )
            );
          } else {
            const isNum = column?.isNum;
            if(isNum) {
              row.push(
                String(
                  roundToSecondDecimalPlace(analyzationResult[column.value as keyof AnalyzationResult] as number) ?? "0"
                )
              );
            } else {
              row.push(
                String(
                  (analyzationResult[column.value as keyof AnalyzationResult] === 0 ? 0 : analyzationResult[column.value as keyof AnalyzationResult] ? roundToSecondDecimalPlace(analyzationResult[column.value as keyof AnalyzationResult] as number) : "-")
                )
              );
            }
          }
        } else {
          row.push("-");
        }
      } else if (column.type === "patient") {
        // -----------------------
        // 患者から出てくる値
        // -----------------------
        const patientData = patients.find(
          (patient) => patient.id === analyzation.patientId
        );
        const affiliationId = patientData?.affiliationId;
        const affiliation = affiliations.find(affiliation => affiliation.id === affiliationId);
        if (patientData) {
          if (column.value === "mainDiseasId") {
            const mainDiseasId = patientData.mainDiseasId;
            const mainDiseas = diseases.find(
              (diseas) => diseas.id === mainDiseasId
            );
            row.push(mainDiseas?.name ?? "");
          } 
          if (column.value === "primaryDiseaseFirstVisitDate") {
            const diseases = patientData.diseases;
            const firstVisitDate = diseases[0]?.firstVisitDate;
            row.push(
              firstVisitDate ? dayjs(firstVisitDate).format("YYYY-MM-DD") : ""
            );
          }  
          if (column.value === "disease") {
            const patientDiseases = patientData.diseases;
            const diseaseId = patientDiseases[0]?.diseasesId;
            const diseaseName = diseases.find(disease => disease.id === diseaseId);
            row.push(diseaseName?.name ?? "");
          }
          if (column.value === "address") {
            row.push(patientData.prefecture ?? "");
          } 
          if(column.value === "affiliationName") {
            row.push(affiliation?.name ?? "");
          }
          if(column.value === "industryName") {
            const industryId = affiliation?.industryId;
            const industry = industries?.find(industry => industry.id === industryId);
            row.push(industry?.name ?? "");
          }
          if(column.value === "occupationName") {
            const occupationId = affiliation?.occupationId;
            const occupation = occupations?.find(occupation => occupation.id === occupationId);
            row.push(occupation?.name ?? "");
          }
          if (!["mainDiseasId", "primaryDiseaseFirstVisitDate", "disease", "address", "affiliationName", "industryName","occupationName"].includes(column.value)){
            // 改行コードを削除
            row.push(String(patientData[column.value as keyof Patients]).replace(/\r?\n/g, ''));
          }
        } else {
          row.push("");
        }
      } else if (column.type === "analyzationResult-calculatedRejectItemList") {
        // -----------------------
        // 結果から出てくる値
        // -----------------------
        const analyzationResult = analyzationResults.find(
          (analyzationResult) =>
            analyzationResult?.analyzationId === analyzation.id
        );
           // 解析データがうつ病の場合は、結果のrejectItemListを表示する
          const moduleName = analyzation.moduleName;
          const versionName = Number((analyzation.analyzerVersionName).split('-')[1]);
          const isSEASD = moduleName === "SEAS-D" ? true : false;
          if(isSEASD){
            const calculatedRejectItemList = analyzationResult?.calculatedRejectItemList;
            const calculatedRejectItemListIndex = Number(column.value.split("-")[1]) ?? "";
            if(versionName!==296 && calculatedRejectItemListIndex > 58){
              row.push("-");
            } else if (analyzationResult && calculatedRejectItemList) {
              const rejectItemIndex = column.value.split("-")[1] ?? "";
              const rejectItem = roundToFourthDecimalPlace(calculatedRejectItemList[Number(rejectItemIndex)-1]);
              row.push(String(rejectItem?? "-") );
            } else {
              row.push("");
            }
          }
      } else {
        row.push(value ? String(value) : "");
      }
    });
    list.push(row);
  });
  return list;
};
